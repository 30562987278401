/************************************************
******** override react-toastify
*************************************************/
:root {
  --toastify-color-light: rgb(255, 255, 255);
  --toastify-color-dark: rgb(18, 18, 18);
  --toastify-color-info: rgb(51, 152, 219);
  --toastify-color-success: rgb(7, 188, 12);
  --toastify-color-warning: rgb(255, 136, 0);
  --toastify-color-error: rgb(231, 76, 60);
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);
}

.Toastify__progress-bar {
  @apply h-1 #{!important};
}

/************************************************
******** override react-pin-input
*************************************************/
.pincode-input-text {
  @apply w-12 h-12 transition rounded-t-md rounded-b-md py-3 px-3 m-auto border border-gray-300 focus:z-10 sm:text-sm focus:ring-primary-500 focus:border-primary-500 mx-2 #{!important};
}

/************************************************
******** override react-datepicker
*************************************************/
.react-datepicker__header {
  @apply bg-gray-100;
}

.react-datepicker__navigation {
  @apply mt-2;
}

.react-datepicker__day :not(.react-datepicker__day--disabled) {
  @apply rounded-md hover:bg-gray-100 #{!important};
}

.react-datepicker__day--today :not(.react-datepicker__day--disabled) {
  @apply border rounded-md bg-gray-100;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected {
  @apply border-none outline-0 bg-primary-500 hover:bg-primary-600 #{!important};
}

.react-datepicker__month-select,
.react-datepicker__year-select {
  @apply border-none outline-0 my-2 #{!important};
}

.react-datepicker__day--in-selecting-range {
  @apply bg-primary-200 #{!important};
}

.react-datepicker__day--in-range {
  @apply bg-primary-500 hover:bg-primary-200 #{!important};
}

/************************************************
******** override react-dropdown
*************************************************/
.Dropdown-control {
  @apply h-12 flex justify-center items-center rounded-full cursor-pointer border border-gray-300 shadow-sm bg-gray-100 text-center text-base font-medium text-gray-700 p-0 hover:bg-gray-50 #{!important};
}

.Dropdown-arrow-wrapper {
  @apply hidden w-0 #{!important};
}

.Dropdown-menu {
  @apply w-12 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 mt-1 #{!important};
}

.Dropdown-option {
  @apply flex justify-center items-center text-gray-700 rounded-md text-base px-4 py-2 m-1 hover:bg-gray-100 #{!important};

  &.is-selected {
    @apply bg-gray-100 #{!important};
  }
}

/************************************************
******** override google maps
*************************************************/
.gm-style-iw,
.gm-style-iw-c,
.gm-style-iw-tc,
.gm-style-cc,
.gmnoprint {
  @apply hidden #{!important};
}

/************************************************
******** override google auto-complete fields
*************************************************/
.pac-item {
  @apply p-2 m-1 rounded-md border-none cursor-pointer hover:bg-gray-100;
}

.pac-logo:after,
.hdpi .pac-icon,
.hdpi.pac-logo:after {
  @apply hidden;
}

/************************************************
******** override mobile webkit-appearance
*************************************************/
input:not([type='range']),
textarea {
  -webkit-appearance: none !important;
}
