@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (hover: none) {
  body * {
    cursor: auto !important;
  }
}

.fixed-footer-actions {
  @apply fixed flex items-center justify-center space-x-2 w-full h-20 z-20 bottom-0 text-gray-600 bg-white border-t px-2 sm:px-6 lg:px-8;
}
